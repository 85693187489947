<template>
    <div class="report-details mh-100vh">
        <div v-if="!endpoint" class="power-bi__error text-center flex flex-column justify-center h-full">
            <p class="w-full text-center" v-text="defaultErrorMessage" />
        </div>
        <div v-else-if="errorMessage" class="power-bi__error text-center flex flex-column justify-center h-full">
            <p class="w-full text-center" v-text="errorMessage" />
        </div>
        <div v-else class="power-bi__wrapper h-full">
            <div v-show="loading" class="relative flex flex__justify-center p-4">
                <spinner />
            </div>
            <div ref="powerBiContainer" :powerbi-access-token="powerBiAccessToken" class="power-bi__report flex flex-col flex-grow" />
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import * as pbi from 'powerbi-client';
import Spinner from '@/components/Spinner.vue';

import { DEFAULT_ANALYTICS_REPORT_SETTINGS } from '@/data/power-bi.js';

export default {
    name: 'ReportDetails',

    components: { Spinner },

    props: {
        endpoint: String,
        defaultErrorMessage: String,
        reportId: String,
    },

    watch: {
        reportId: {
            immediate: true,
            handler(val) {
                if (!val) {
                    return;
                }

                this.updateReport(val);
            },
        },
    },

    data() {
        return {
            powerBiAccessToken: '',
            errorMessage: null,
            loading: false,
            powerbi: null,
        }
    },

    mounted() {
        this.powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory,
        );

        if (!this.reportId) {
            return;
        }

        this.updateReport(this.reportId);
    },

    methods: {
        getApiError(data) {
            if (typeof data === 'string') {
                return data;
            }

            let message = data?.response?.status === 404 ? this.defaultErrorMessage : data?.response?.data?.message;

            if (!message) {
                this.$snotify.error('An error occured');

                return null;
            }

            return message;
        },
        async getReportData(id) {
            const { data: { data } } = await axios.get(`${this.$apiUrl.powerBi.base}/${id}`);

            return data;
        },
        async updateReport(id) {
            if (!this.powerbi) {
                return;
            }

            this.errorMessage = null;
            this.loading = true;

            try {
                // reset report container element
                this.powerbi.reset(this.$refs.powerBiContainer);
                
                const data = await this.getReportData(id);

                if (data.error) {
                    throw Error(data.response);
                }
        
                this.isShowErrorText = false;
        
                const models = pbi.models;
                let permissions = models.Permissions.All;
        
                this.powerBiAccessToken = data.access_token;
                const reportId = data.embed_url
                    .split('&')
                    .find(arg => arg.indexOf('reportId') !== -1)
                    .split('=')[1];

                let settings;

                if (this.reportId === 'analytics') {
                    settings = DEFAULT_ANALYTICS_REPORT_SETTINGS;
                } else if (data.front_options) {
                    try {
                        settings = JSON.parse(data.front_options);
                    } catch (error) {
                        throw Error('Invalid JSON passed');
                    }
                }
        
                let config = {
                    accessToken: this.powerBiAccessToken,
                    embedUrl: data.embed_url,
                    id: reportId,
                    type: 'report',
                    tokenType: models.TokenType.Embed,
                    permissions,
                    settings,
                };
        
                const report = this.powerbi.embed(this.$refs.powerBiContainer, config);
        
                report.off("loaded");
                report.off("rendered");
                report.on("error", function (error) {
                    console.warn('error', error);
                });
            } catch (error) {
                if (error?.response?.status === 404) {
                    this.errorMessage = 'No report data found';

                    return;
                }

                if (Array.isArray(error) && error[0]?.message) {
                    this.errorMessage = error[0].message;

                    return;
                }

                this.errorMessage = this.getApiError(error);
            }

            this.loading = false;
        },
    },
}
</script>
<style lang="scss">
.mh-100vh {
    min-height: 100vh;
}
</style>