<template>
    <report-component :endpoint="this.$apiUrl.powerBi.base" :report-id="String($route.params.id)" />
</template>
<script>

import ReportComponent from '~/components/Report.vue';

export default {
    name: 'ReportSingle',

    components: { ReportComponent },

    created() {
        if (!this.$route.params.id) {
            this.$router.push('/');
        }
    },
}
</script>
<style lang="scss">
@import '~/styles/components/_power-bi.scss';
</style>
